
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from '@/services/genesyscloud-service'

export default defineComponent({
  data () {
    return {
      searchDivision: '',
      divisions: [] as platformClient.Models.AuthzDivision[],
      filteredDivisions: [] as platformClient.Models.AuthzDivision[],
      showDivisions: false,
      divisionLoading: false,
      debounceTimer: null as unknown as ReturnType<typeof setTimeout>
    }
  },
  watch: {
    searchDivision (newValue) {
      if (!newValue) {
        this.$emit('divisionCleared')
      }
    }
  },
  methods: {
    async fetchDivisions () {
      try {
        this.divisionLoading = true
        this.divisions = await genesysCloudService.getDivisions() || []
      } catch (error) {
        console.error('Error fetching divisions:', error)
      } finally {
        this.divisionLoading = false
      }
    },

    debounceSearch () {
      if (this.debounceTimer) clearTimeout(this.debounceTimer)

      this.debounceTimer = setTimeout(() => {
        this.filterDivisions()
      }, 300) // Adjust debounce time if needed
    },

    filterDivisions () {
      if (this.searchDivision.length < 3) {
        this.showDivisions = false
        this.filteredDivisions = []
        return
      }

      const regex = new RegExp(this.searchDivision, 'i')
      this.filteredDivisions = this.divisions.filter(division => division.name?.match(regex))

      this.showDivisions = this.filteredDivisions.length > 0
    },

    selectDivision (division: platformClient.Models.AuthzDivision) {
      if (division.name && division.id) {
        this.searchDivision = division.name
        this.filteredDivisions = []
        this.showDivisions = false
        this.$emit('divisionSelected', division)
      }
    }
  },
  async created () {
    await this.fetchDivisions() // ✅ Load all divisions on component creation
  },
  computed: {
    divisionResult (): boolean {
      return this.filteredDivisions.length === 0
    }
  }
})
