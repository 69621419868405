
import { defineComponent } from 'vue'
import platformClient from 'purecloud-platform-client-v2'
import genesysCloudService from './../services/genesyscloud-service'
import DataTable from '@/components/DataTable.vue'
import Spinner from '@/components/Spinner.vue'
// import Vue3EasyDataTable from 'vue3-easy-data-table'
import 'vue3-easy-data-table/dist/style.css'
import DivisionSearch from '@/components/DivisionSearch.vue'
// import { ClickRowArgument, Item } from 'vue3-easy-data-table'
interface ExtendedQueue extends platformClient.Models.Queue {
  oWaiting?: number;
  oInteracting?: number;
}
interface Metric {
  metric: string;
  stats: {
    count: number;
  };
}

interface Data {
  interval: string;
  metrics: Metric[];
}

interface eventBody {
  group: {
    queueId: string;
    mediaType: string;
  };
  data: Data[];
}

type conversation = {
  conversationId? : string,
  conversationStart?: string,
  name?: string,
  externalContactId?: string,
  participantId?: string,
  transferredQueue?: string,
  isTransferOut?: boolean,
  agent?: string,
  queue?: string,
  from?: string,
  subject?: string,
  state?: string,
  isEmpty?: false,
  direction?: string,
  to?: string,
  participants?: any[]
}

export default defineComponent({
  name: 'Monitor',
  components: { DataTable, Spinner, DivisionSearch },
  props: ['userId', 'divisionId'],
  data () {
    return {
      isItemLoading: false,
      divisionLoading: false,
      searchDivision: '',
      searchQuery: '',
      timeout: 0,
      pageSize: 25,
      pageNumber: 1,
      queuename: '',
      queueWaiting: 0,
      currentQueueWaiting: 0,
      currentQueueInteracting: 0,
      currentIndex: -1,
      queueIds: [] as string[],
      queues: [] as ExtendedQueue[],
      queue: {} as ExtendedQueue[],
      data: [] as platformClient.Models.QueueObservationDataContainer[],
      conversations: [] as Array<conversation>,
      wrapupcodes: [] as { name: string, id: string | undefined}[],
      selectedDivision: '', // Stores the selected division ID
      divisions: [] as platformClient.Models.AuthzDivision[], // Stores all divisions
      divisionsLoading: false,
      showDivisions: false,
      headers: [
        { text: this.$t('datatable.headers.name'), value: 'name', sortable: true },
        { text: this.$t('datatable.headers.oWaiting'), value: 'displayOWaiting', sortable: true },
        { text: this.$t('datatable.headers.oInteracting'), value: 'displayOInteracting', sortable: true }
      ],
      items: [] as ExtendedQueue[]
    }
  },
  watch: {
    selectedDivision (newDivision) {
      this.fetchData()
    },
    async searchQuery (newValue) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }
      this.timeout = setTimeout(async () => {
        this.pageNumber = 1
        await this.fetchData(newValue)
      }, 1500)
    }
  },
  methods: {
    async fetchDivisions () {
      this.divisionLoading = true
      try {
        const response = await genesysCloudService.getDivisions()
        if (response) {
          this.divisions = response
        }
      } catch (error) {
        console.error('Error fetching divisions:', error)
      } finally {
        this.divisionLoading = false
      }
    },
    setDivision (division) {
      if (!division || !division.id) {
        this.selectedDivision = ''
      } else {
        this.selectedDivision = division.id
        this.searchDivision = division.name
      }

      this.showDivisions = false
      this.fetchData()
    },
    clearDivision () {
      this.selectedDivision = ''
      this.fetchData()
    },

    async fetchData (search = '') {
      const divisionToUse = this.selectedDivision || ''
      try {
        let pageNumber = 1
        const pageSize = 25
        let totalQueues: ExtendedQueue[] = []

        while (true) {
          const queues = await genesysCloudService.getQueues(search, divisionToUse, pageSize, pageNumber)

          if (!queues || queues.length === 0) break

          totalQueues = [...totalQueues, ...queues]
          pageNumber++
        }

        this.queueIds = totalQueues.map(queue => queue.id ?? '')

        if (this.queueIds.length === 0) {
          console.log('No queue Ids were provided')
          return
        }
        const predicateQueues = this.queueIds.map(item => ({
          dimension: 'queueId',
          value: item
        })) as platformClient.Models.QueueObservationQueryPredicate
        const res = await genesysCloudService.postQueueObservation(predicateQueues)
        if (res) {
          this.data = res
        }
        this.queues = totalQueues as ExtendedQueue[]
        this.queues.forEach(queue => {
          queue.oWaiting = this.getMetric(queue, 'oWaiting')
        })
        this.queues.forEach(queue => {
          queue.oInteracting = this.getMetric(queue, 'oInteracting')
        })
        this.items = this.queues
        await genesysCloudService.subscribeToQueuesObservations(this.queueIds, [this.onQueueEvent])
      } catch (err) {
        console.error(err)
      }
    },
    onQueueEvent (message: MessageEvent): void {
      const data = JSON.parse(message.data)
      const topicName = data.topicName
      const eventBody = data.eventBody as eventBody
      // Update agent view
      const topicRegex = /(v2\.analytics.queues\.)(.*)\.(.*)/g
      const match = topicRegex.exec(topicName)
      if (!match) return
      const queueId = match[2]
      const index = (this.queues as ExtendedQueue[]).findIndex(queue => queue.id === queueId)
      if (eventBody.group.mediaType === 'email') {
        eventBody.data[0].metrics.forEach(el => {
          const queue = this.queue as ExtendedQueue
          if (el.metric === 'oWaiting') {
            (this.queues[index] as ExtendedQueue).oWaiting = el.stats.count
            queue.oWaiting = el.stats.count
            if (el.stats.count === this.currentQueueWaiting) {
            } else {
              if (queue.id === queueId) {
                (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
              }
            }
          } else if (el.metric === 'oInteracting') {
            (this.queues[index] as ExtendedQueue).oInteracting = el.stats.count
            queue.oInteracting = el.stats.count
            if (el.stats.count === this.currentQueueInteracting) {
            } else {
              if (queue.id === queueId) {
                (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
              }
            }
          }
        })
      }

      // eventBody.data[0].metrics.forEach((element) => {
      //   if (element.metric === 'oInteracting') {
      //     this.queues[index].oInteracting = element.stats.count
      //   } else if (element.metric === 'oWaiting') {
      //     this.queues[index].oWaiting = element.stats.count
      //   }
      //   if (this.queue.id === queueId) {
      //     (this.$refs.childComponent as { toggleReloadButoon: () => void }).toggleReloadButoon()
      //   }
      // })
    },
    getMetric (queue: platformClient.Models.Queue, metricName: string) {
      const queueId = queue.id
      const el = this.data.findIndex(d => d.group && d.group.queueId === queueId)
      const data = this.data[el]?.data
      if (data) {
        const metric = data.find(metric => metric.metric === metricName)
        // this.currentW = metric?.stats?.count || 99
        return metric?.stats?.count
      }
    },
    async getConversationDetails (queue: ExtendedQueue[]) {
      const queues = queue as ExtendedQueue
      if (queues.oWaiting === 0 && queues.oInteracting === 0 && Object.keys(this.queue).length === 0) return

      // Hide reload button and reset selected items
      this.hideAndResetComponents()

      if (!queues.id) {
        console.error('Queue ID is undefined!!')
        return
      }

      this.prepareLoadingState(queues)
      this.queue = queue

      try {
        const res = await genesysCloudService.postAnalyticsConversationsdetails(queues.id)
        if (!res || !res.conversations) {
          this.resetConversations()
          return
        }

        const conversations = await this.processConversations(res.conversations, queues.id)

        this.conversations = conversations.filter((conversation) => !conversation.isEmpty)
        this.isItemLoading = false

        await this.fetchWrapUpCodes(queues.id)
      } catch (error) {
        console.error(error)
        this.isItemLoading = false
      }
    },

    hideAndResetComponents () {
      (this.$refs.childComponent as { hideReload: () => void }).hideReload();
      (this.$refs.childComponent as { resetItemSelected: () => void }).resetItemSelected()
    },

    prepareLoadingState (queues: ExtendedQueue) {
      this.isItemLoading = true
      this.currentQueueWaiting = queues.oWaiting || 0
      this.currentQueueInteracting = queues.oInteracting || 0
      this.queuename = queues.name || ''
    },

    async processConversations (conversations: Array<conversation>, queueId: string) {
      return Promise.all(
        conversations.map(async (item) => {
          const participants = item.participants
          if (!participants) return { conversationId: item.conversationId }

          const filteredParticipants = this.filterParticipants(participants)

          const lastAgent = this.getLastAgentWithUnfinishedSegment(filteredParticipants, queueId)
          if (!lastAgent) return { isEmpty: true } as any
          if (this.isOutboundWithoutAcd(lastAgent, filteredParticipants)) {
            return await this.handleOutboundConversation(item, lastAgent)
          }
          const state = this.determineSessionState(filteredParticipants)
          return await this.createConversationDetails(item, lastAgent, filteredParticipants, queueId, state)
        })
      )
    },
    isOutboundWithoutAcd (lastAgent: any, participants: any[]) {
      return lastAgent.sessions?.length === 1 &&
        lastAgent.sessions[0].direction === 'outbound' &&
        !participants.some(participant => participant.purpose === 'acd')
    },

    async handleOutboundConversation (item: any, lastAgent: any) {
      const startTime = lastAgent.sessions?.[0].metrics?.find(metric => metric.name === 'nOutbound')
      const user = await genesysCloudService.getUserById(lastAgent.userId || '')

      return {
        conversationId: item.conversationId,
        from: lastAgent.sessions?.[0].addressFrom,
        to: lastAgent.sessions?.[0].addressTo,
        agent: user.name,
        direction: 'Sortant',
        participantId: lastAgent.participantId,
        transferredQueue: '',
        isTransferOut: false,
        externalContactId: '',
        subject: '',
        state: 'Interaction',
        conversationStart: new Date(startTime?.emitDate || '').toLocaleString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        })
      }
    },
    filterParticipants (participants: any[]) {
      return participants.map((participant) => ({
        ...participant,
        sessions: participant.sessions?.map((session) => ({
          ...session,
          segments: session.segments?.filter((segment) => {
            return !(segment.segmentType === 'parked' && !segment.segmentEnd)
          }) || []
        })) || []
      }))
    },

    getLastAgentWithUnfinishedSegment (participants: any[], queueId: string) {
      return participants.reduce((last: any, current: any) => {
        const isTargetPurpose = current.purpose === 'agent' || current.purpose === 'user' || current.purpose === 'acd'
        const hasUnfinishedSegment = current.sessions?.some((session) =>
          session.segments?.some((segment) => !segment.segmentEnd)
        )
        const lastSegmentQueueId = current.sessions?.[current.sessions.length - 1]?.segments?.[0]?.queueId

        return isTargetPurpose && hasUnfinishedSegment && lastSegmentQueueId === queueId ? current : last
      }, null)
    },
    determineSessionState (participant: any) {
      const agentSessionsArr = participant.flatMap(agent =>
        (agent.purpose === 'agent' || agent.purpose === 'user') && agent.sessions
          ? agent.sessions.pop()
          : []
      )
      const acdSession = participant.flatMap(acd =>
        acd.purpose === 'acd' && acd.sessions
          ? acd.sessions.pop()
          : []
      )

      // Determine status based on segment types
      const sessionWithAlert = agentSessionsArr.find((session: any) =>
        session.segments?.some((segment: any) => segment.segmentType === 'alert' && !segment.segmentEnd)
      )

      const sessionWithInteraction = agentSessionsArr.find((session: any) =>
        session.segments?.some((segment: any) => segment.segmentType === 'interact' && !segment.segmentEnd)
      )

      const sessionWithHold = agentSessionsArr.find((session: any) =>
        session.segments?.some((segment: any) => segment.segmentType === 'hold' && !segment.segmentEnd)
      )
      const sessionWithTransferAcd = acdSession.find((session: any) =>
        session.segments?.some((segment: any) => segment.segmentType === 'interact' && !segment.segmentEnd)
      )

      if (sessionWithAlert) {
        return 'Alerte'
      } else if (sessionWithInteraction || sessionWithHold) {
        return 'Interaction'
      } else if (sessionWithTransferAcd) {
        return 'En attente'
      }
    },

    async createConversationDetails (item: any, lastAgent: any, filteredParticipants: any[], queueId: string, state) {
      const agent = lastAgent?.participantName || ''
      const agentParticipantId = lastAgent?.participantId || ''

      const externalParticipant = this.getExternalParticipant(filteredParticipants)
      const acdParticipant = this.getAcdParticipant(filteredParticipants)
      const subject = this.getSubject(externalParticipant)
      const [addressFrom, addressTo] = this.getAddresses(externalParticipant, acdParticipant)

      const conversationDetails: conversation = {
        conversationId: item.conversationId,
        queue: acdParticipant?.participantName || '',
        participantId: agentParticipantId,
        transferredQueue: acdParticipant?.participantName !== this.queuename ? acdParticipant?.participantName : '',
        isTransferOut: acdParticipant?.participantName !== this.queuename,
        agent,
        state,
        direction: 'Entrant',
        from: addressFrom,
        to: addressTo,
        name: externalParticipant?.participantName || '',
        externalContactId: externalParticipant?.externalContactId || '',
        conversationStart: new Date(item.conversationStart || '').toLocaleString([], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        }),
        subject
      }

      // Add skills if available
      const skills = acdParticipant?.sessions[0]?.segments[0]?.requestedRoutingSkillIds || []
      if (skills.length > 0) {
        const skillNames = await this.processSkillsAndGetSkill(skills)
        return { ...conversationDetails, skills: skillNames }
      }

      return conversationDetails
    },

    getExternalParticipant (participants: any[]) {
      return participants.find((p) => p.purpose === 'external' || p.purpose === 'customer')
    },

    getAcdParticipant (participants: any[]) {
      return participants.reverse().find((p) => p.purpose === 'acd')
    },

    getAddresses (externalParticipant: any, acdParticipant: any) {
      const addressFrom = externalParticipant?.sessions?.[0]?.addressFrom || ''
      const addressTo = acdParticipant?.sessions?.[0]?.addressTo || ''
      return [addressFrom, addressTo]
    },

    getSubject (externalParticipant: any) {
      return externalParticipant?.sessions?.[0]?.segments?.[0]?.subject || ''
    },

    async fetchWrapUpCodes (queueId: string) {
      const wraps = await genesysCloudService.getQueueWrapupCodes(queueId)
      if (wraps) {
        this.wrapupcodes = wraps.map((wrap) => ({ name: wrap.name, id: wrap.id }))
      }
    },

    resetConversations () {
      this.conversations = []
      this.wrapupcodes = []
      this.isItemLoading = false
    },
    async processSkillsAndGetSkill (skillids: string[]) {
      const skillnames = await Promise.all(
        skillids.map(async (skill) => {
          const sk = await genesysCloudService.getSkill(skill)
          return sk.name
        })
      )
      return skillnames
    }
  },
  async created () {
    await this.fetchDivisions()
    this.fetchData()
  }
})
